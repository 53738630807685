const NAMESPACE_MUI = 'GEORGE_GRACIE_';

const actions = {

  FETCH_ENTITY: `${NAMESPACE_MUI}FETCH_ENTITY`,
  FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}FETCH_ENTITY_SUCCESSFUL`,

  LOGIN_USER: `${NAMESPACE_MUI}LOGIN_USER`,
  LOGIN_USER_SUCCESSFUL: `${NAMESPACE_MUI}LOGIN_USER_SUCCESSFUL`,
  LOGIN_USER_FAILED: `${NAMESPACE_MUI}LOGIN_USER_FAILED`,
  LOGOUT_USER: `${NAMESPACE_MUI}LOGOUT_USER`,
  
};

export { actions as default };